"use client";

import { IoIosMenu } from "react-icons/io";
import Link from "next/link";
import Image from "next/image";
export default function Navbar() {
  return (
    <div className="fixed z-40 w-full md:px-32 py-2 3xl:py-4 bg-[#00718F]">
      <div className="py-2 rounded-full flex justify-between items-center md:px-14 px-4">
        <Link href="/">
          <Image
            width={100}
            height={100}
            src={require("../assets/svg/cards-logo-white.svg")}
            alt="Cards logo"
            className="lg:w-28 w-16"
          />
        </Link>
        <div className="lg:flex items-center gap-[100px] hidden text-sm">
          <div className="hidden lg:block text-sm">
            <Link
              href="/kontak"
              className="btn btn-accent text-white font-semibold rounded-full !min-h-0 h-full btn-sm py-2 text-sm px-10"
            >
              Kontak
            </Link>
          </div>
        </div>
        <div className="block lg:hidden">
          <label htmlFor="my-drawer" className="cursor-pointer drawer-button">
            <IoIosMenu className="w-8 h-8 text-white" />
          </label>
        </div>
      </div>
    </div>
  );
}
